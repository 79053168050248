/**
 * This is broken for Russian and PTR languages. Therefore below is the css from the plugin to
 * ensure all languages behave the same way.
 */
:root {
  --selector-width: 128px;
  --background-color: rgba(255, 255, 255, 0.70);
  --border-color: rgba(211, 210, 206, 0.50);
}

/* Primary Container */
body .GLGO-language-selector {
  bottom: 0;
  font-family: 'SofiaProSoftBold', sans-serif;
  left: 0;
  position: fixed;
  width: var(--selector-width);
  white-space: nowrap;
  z-index: 50;
}

/* Currently selected indicator */
body .oljs-select-selected {
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top-right-radius: 8px;
  color: #292927 !important;
  font-family: inherit;
  font-size: 13px;
  position: relative;
  z-index: 20;
}

body .oljs-select-selected,
body .oljs-select-items {
  background: var(--background-color);
  backdrop-filter: blur(8px);
}

/* Text */
.oljs-select-items div,
.oljs-select-selected {
  font-family: 'SofiaProSoftBold', sans-serif;
  font-size: 13px !important;
  padding: 8px 20px 8px 12px;
  border: 1px solid transparent;
  border-right-width: 6px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-height: 1.5em !important;
}

/* Arrow indicator */
body .oljs-select-selected::after {
  border-color: #292927 transparent transparent transparent;
}

.oljs-select-selected::after {
  position: absolute;
  bottom: 40%;
  right: 8px;
  z-index: 999;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #FFFFFF transparent transparent transparent;
  transform: rotateZ(180deg);
  transition: 0.5s;
}

body .oljs-select-selected.oljs-select-arrow-active:after {
  border-color: transparent transparent #292927 transparent;
  bottom: 20%;
}

/* Dropdown for other options */
body .oljs-select-items {
  border: 1px solid var(--border-color);
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 100%;
  margin-bottom: 3px;
  max-height: 500px;
  position: absolute;
  transition-property: max-height;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  width: var(--selector-width);
  z-index: 10;
}

body .oljs-select-items div {
  color: #292927 !important;
}

body .oljs-select-items div:hover,
body .oljs-select-selected:hover {
  background: var(--background-color);
  filter: brightness(.95);
}

body .oljs-select-hide {
  display: block;
  overflow: hidden;
  background: transparent;
  border-color: transparent;
  max-height: 0;
}
