 .animatedcursor  {
	position: fixed;
	top: -50px;
	left: -50px;
	width: 120px;
	height: 120px;
	border-radius: 100%;
	z-index: 10000;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #292927;
    backdrop-filter: blur(5px);
	display: flex;
    align-items: center;
    justify-content: center;
	background: #EED65B;
	border: 2px solid #292927;
	backdrop-filter: blur(5px);
	border-radius: 100px;
}

.animatedcursorDrag  {
	position: fixed;
	top: -50px;
	left: -50px;
	width: 100px;
	height: 100px;
	z-index: 10000;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #292927;
    backdrop-filter: blur(5px);
	display: flex;
    align-items: center;
    justify-content: center;
	border-radius: 100px;
}

.animatedcursorDefault  {
	position: fixed;
	top: -20px;
	left: -20px;
	width: 20px;
	height: 20px;
	z-index: 10000;
    pointer-events: none;
    background: #EED65B;
	display: flex;
    align-items: center;
    justify-content: center;
	backdrop-filter: blur(5px);
	border-radius: 100px;
	font-size: 0;
}

.animatedcursorBtn{
	position: fixed;
	top: -20px;
	left: -20px;
	width: 10px;
	height: 10px;
	z-index: 10000;
    pointer-events: none;
    background: #EED65B;
	display: flex;
    align-items: center;
    justify-content: center;
	backdrop-filter: blur(5px);
	border-radius: 100px;
	font-size: 0;
}